.btn-check {
    @apply absolute pointer-events-none;
    clip: rect(0, 0, 0, 0);

    + .btn {
        @apply inline-grid;
    }
    
    &:checked {
        + .btn-outline-primary {
            @apply bg-primary text-white hover:bg-primary-600 focus:bg-primary-600;
        }
    
        + .btn-outline-secondary {
            @apply bg-light-300 text-gray-500 hover:bg-light-600 focus:bg-light-800;
        }
    
        + .btn-outline-success {
            @apply bg-green-500 text-white hover:bg-green-600 focus:bg-green-600;
        }
    
        + .btn-outline-danger {
            @apply bg-red-500 text-white hover:bg-red-600 focus:bg-red-600;
        }
    
        + .btn-outline-warning {
            @apply bg-yellow-500 text-white hover:bg-yellow-600 focus:bg-yellow-600;
        }
    
        + .btn-outline-info {
            @apply bg-blue-500 text-white hover:bg-blue-600 focus:bg-blue-600;
        }
    }
}