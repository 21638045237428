.full-height > .application {
    @apply h-full;
}

.bill-table {
    @apply w-full text-gray-600;

    & th {
        @apply text-gray-600 font-medium align-middle border-gray-300;
    }

    & > :not(:last-child) > :last-child > * {
        @apply border-gray-300;
    }
    
    & > :not(caption) > * > * {
        @apply px-5 py-7;
    }

    & .separator {
        @apply border-b;
    }
}