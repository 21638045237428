.footer {
    @apply fixed bg-white bottom-0 right-0 px-20 py-10 mt-20;

    &-default {
        @apply bg-white;
    }

    &-border {
        @apply border-t border-white;
    }
}